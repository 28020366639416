import React from 'react';
import ReactDOM from 'react-dom';
import { Menu } from 'react-feather';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { cx } from 'src/shared/utils/common';
import { HeaderUserAvatar } from 'src/features/appLayout/ui/HeaderUserAvatar';
import { useSidebarVisibilityContext } from 'src/features/appLayout/SidebarContext';
import { AuthContextProvider } from 'src/context/authContext';
import { AppNotifications } from 'src/features/appLayout/ui/AppNotifications';
import { Breadcrumb } from 'src/features/appLayout/ui/PageHeader/Breadcrumb';
import css from './PageHeaderBar.module.scss';
import { useAuthContextQuery } from 'src/context/authContext/queries/authContextQuery';

const HeaderBar: React.FC = observer(() => {
  const { toggleVisibility, isTopbarDisplay } = useSidebarVisibilityContext();
  const position = useLocalObservable(() => {
    // let timeout: NodeJS.Timeout | undefined = undefined;

    return {
      // next: 0, // current position after debounce
      // previous: 0, // previous position before debounce
      showShadow: false,
      // get hideOnScrollDown() {
      //   return this.next > this.previous;
      // },
      setPosition(scrollPosition: number) {
        this.showShadow = scrollPosition > 0;

        // if (!timeout) {
        //   timeout = setTimeout(
        //     action(() => {
        //       this.previous = this.next;
        //       this.next = scrollPosition;
        //       timeout = undefined;
        //     }),
        //     20,
        //   );
        // }
      },
    };
  });

  React.useEffect(() => {
    const handleScroll = () => position.setPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [position]);

  const { data: currentUser } = useAuthContextQuery();
  const currentClub = currentUser?.permissions.find(club => club.clubId === currentUser.currentClub);

  return (
    <header
      className={cx(
        'position-fixed top-0 start-0 end-0',
        css.headerBar,
        position.showShadow && css.showShadow,
        isTopbarDisplay && css.showBar,
        // position.hideOnScrollDown && css.hideOnScrollDown,
      )}
    >
      <div className={cx('h-100 d-flex align-items-center', css.headerPadding)}>
        <button
          className={cx('p-0', css.menuButton)}
          onClick={toggleVisibility}
          name='Open menu'
          aria-label='Open menu'
        >
          <Menu className='d-block' />
        </button>
        <div>
          <span className='ts-fw-bolder ts-fs-16 ms-3'>{currentClub?.clubName}</span>
          <Breadcrumb className='ms-3' showOnlyFirstPath />
        </div>

        <div className={cx('d-flex align-items-center ms-auto', css.controls)}>
          <AuthContextProvider>
            <AppNotifications />
            <HeaderUserAvatar />
          </AuthContextProvider>
        </div>
      </div>
    </header>
  );
});

const TOP_BAR_ROOT_ID = 'root';
const topBarRoot = document.getElementById(TOP_BAR_ROOT_ID);

export const PageHeaderBar: React.FC = () => {
  if (!topBarRoot) {
    throw new Error(`Element with "${TOP_BAR_ROOT_ID}" ID could not be found!`);
  }

  return ReactDOM.createPortal(<HeaderBar />, topBarRoot);
};
