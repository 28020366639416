import React from 'react';
import { autorun } from 'mobx';
import {
  BarChart2,
  Clipboard,
  Calendar,
  Users,
  Icon,
  PlayCircle,
  Shield,
  Video,
  Briefcase,
  CreditCard,
  Crosshair,
  Unlock,
  MessageCircle,
} from 'react-feather';
import { useAuthContextQuery } from 'src/context/authContext/queries/authContextQuery';
import { useI18nContext } from 'src/context/i18n';
import { useAppRoutesContext } from 'src/context/routes';
import { PlayBookIcon } from 'src/shared/ui/assets/PlayBookIcon';
import { BasketballIcon } from 'src/shared/ui/assets/BasketballIcon';
import { SUBSCRIPTION_PLAN } from 'src/pages/billing/model/model.types';

export interface NavigationItemChild {
  id: number | string;
  label: string;
  url: string;
}

export interface NavigationItem {
  children?: NavigationItemChild[];
  icon: Icon | null;
  id: NavItemId;
  label: string;
  url?: string;
  disabled?: boolean;
  isStripe?: boolean;
}

const navItemsIds = {
  events: 'events',
  entries: 'entries',
  players: 'players',
  coaches: 'coaches',
  chat: 'chat',
  teams: 'teams',
  clubs: 'clubs',
  gameStats: 'gameStats',
  trainingStats: 'trainingStats',
  trainingGoals: 'trainingGoals',
  reports: 'reports',
  videos: 'videos',
  videoClips: 'videoClips',
  videoTags: 'videoTags',
  exercises: 'exercises',
  divider: 'divider',
  unlockAllFeatures: 'unlockAllFeatures',
  payments: 'payments',
} as const;
export type NavItemId = keyof typeof navItemsIds;
export const APPBAR_DIVIDER_ID = navItemsIds.divider;

export function useNavigationItems(): NavigationItem[] {
  const i18n = useI18nContext();
  const routes = useAppRoutesContext();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { data: currentUser } = useAuthContextQuery();
  const isFreeAccount = currentUser?.currentPaymentPlan === SUBSCRIPTION_PLAN.free;
  const currentClub = currentUser?.permissions.find(club => club.clubId === currentUser.currentClub);
  const isLeague = currentClub?.clubName.toLowerCase().includes('league');

  React.useEffect(
    () =>
      autorun(() => {
        if (currentUser?.isAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }),
    [currentUser, currentUser?.currentRole],
  );

  const navItems = React.useMemo<NavigationItem[]>(
    () => [
      {
        id: navItemsIds.events,
        label: i18n.t('shared.navigation.events'),
        url: routes.events.base,
        icon: Calendar,
      },
      {
        id: navItemsIds.entries,
        label: i18n.t('shared.navigation.entries'),
        url: routes.entries.base,
        icon: Users,
        disabled: isLeague,
      },
      ...(isAdmin
        ? [
            {
              id: navItemsIds.players,
              label: i18n.t('shared.navigation.players'),
              url: routes.players.base,
              icon: Users,
            },
          ]
        : []),
      {
        id: navItemsIds.coaches,
        label: 'Coaches & Staff',
        url: routes.coaches.base,
        icon: Briefcase,
      },
      {
        id: navItemsIds.teams,
        label: i18n.t('shared.navigation.teams'),
        url: routes.teams.base,
        icon: Shield,
      },
      {
        id: navItemsIds.chat,
        label: i18n.t('pages.chat.pageTitle'),
        url: routes.chat.base,
        icon: MessageCircle,
        disabled: isLeague,
      },
      {
        id: navItemsIds.payments,
        label: 'Payments',
        url: routes.products.base,
        icon: CreditCard,
        disabled: isLeague,
      },
      ...(isAdmin
        ? [
            {
              id: navItemsIds.clubs,
              label: 'Clubs',
              url: routes.clubs.base,
              icon: CreditCard,
            },
          ]
        : []),
      { id: APPBAR_DIVIDER_ID, label: '', icon: null },

      {
        id: navItemsIds.gameStats,
        label: i18n.t('shared.navigation.gameStats'),
        url: routes.gameStats.base,
        icon: PlayBookIcon,
      },
      {
        id: navItemsIds.trainingStats,
        label: i18n.t('shared.navigation.trainingStats'),
        url: routes.trainingStats,
        icon: BarChart2,
        disabled: isLeague,
      },
      {
        id: navItemsIds.trainingGoals,
        label: i18n.t('shared.navigation.targets'),
        url: routes.targets.base,
        icon: Crosshair,
        disabled: isLeague,
      },
      {
        id: navItemsIds.reports,
        label: 'Reports',
        url: routes.reports.base,
        icon: Clipboard,
        disabled: isLeague,
      },
      { id: APPBAR_DIVIDER_ID, label: '', icon: null },

      {
        id: navItemsIds.videos,
        label: i18n.t('shared.navigation.videos'),
        url: routes.videos.base,
        icon: PlayCircle,
        disabled: isLeague,
      },
      {
        id: navItemsIds.videoClips,
        label: i18n.t('shared.navigation.highlights'),
        url: routes.highlights.base,
        icon: Video,
        disabled: isLeague,
      },
      { id: APPBAR_DIVIDER_ID, label: '', icon: null },
      ...(isAdmin
        ? [
            {
              id: navItemsIds.exercises,
              label: i18n.t('shared.navigation.exercises'),
              url: routes.exercises.base,
              icon: BasketballIcon,
            },
            { id: APPBAR_DIVIDER_ID, label: '', icon: null },
          ]
        : []),
      ...(isFreeAccount
        ? [
            {
              id: navItemsIds.unlockAllFeatures,
              label: 'Unlock all features',
              url: `https://buy.stripe.com/dR63cx92HcOs7ra4gk?client_reference_id=259946&prefilled_email=${currentUser.email}&client_reference_id=${currentUser.currentClub}`,
              icon: Unlock,
              disabled: isLeague,
              isStripe: true,
            },
          ]
        : []),
      // {
      //   id: Math.random(),
      //   label: i18n.t('shared.navigation.faqs'),
      //   url: routes.faqCentre,
      //   icon: Clipboard,
      // },
    ],
    [i18n, routes, isAdmin, isFreeAccount],
  );

  return navItems;
}
